import React, { useState } from "react";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import { format } from 'date-fns';

import Layout from "../components/layout/Layout";
import { Button, SimpleButtonSymbolHover } from "../components/Button";
import ShareButtons from "../components/ShareButtons";
import VimeoPlayer from "../components/VimeoPlayer";
import ContentHTMLWithHoverPopUps from "../components/ContentHTMLWithHoverPopUps";

import { pushToGTM, getDefinedTermPopUpItem } from "../helpers";

export default ({ data, location }) => {
    const {
        title,
        slug,
        image,
        createdAt,
        overview,
        vimeoEmbedUrl,
        metaTags,
    } = data.contentfulVideo;

    const seoData = {
        image: image && image.file.url,
        slug,
        createdAt,
        metaTags
    };

    const pushWVideoPlayEventToGTM = (e) => {
        pushToGTM({
            'event': 'video_play_start',
            'video_name': title,
            'duration': e.duration,
            'playback_duration_s': e.seconds,
            'playback_duration_percent': e.percent,
        });
    }

    const pushVideoStopEventToGTM = (e) => {
        pushToGTM({
            'event': 'video_play_end',
            'video_name': title,
            'duration': e.duration,
            'playback_duration_s': e.seconds,
            'playback_duration_percent': e.percent,
        });
    }

    const pushVideoPlayingEventToGTM = (e) => {
        pushToGTM({
            'event': 'video_playing',
            'video_name': title,
            'duration': e.duration,
            'playback_duration_s': e.seconds,
            'playback_duration_percent': e.percent,
        });
    }

    const [mobileVideoPlayerShown, setMobileVideoPlayerShown] = useState(false);
    const handleMobileWatchVideoButtonClick = () => setMobileVideoPlayerShown(cur => !cur);

    const definedTermsPopUpItems = data.allContentfulDefinedTerm.nodes.map(getDefinedTermPopUpItem);

    return (
        <Layout
            title={title}
            seoData={seoData}
            pageContentId="video-content"
            transparentNavigation={true}
            isVideo={true}
            location={location}
        >
            <nav className="webinar-mobile-watch-container is-hidden-tablet" role="navigation" aria-label="watch the video">
                <div className="webinar-mobile-watch-title">
                    <p className="is-white size-24">
                        watch the video
                    </p>
                    <SimpleButtonSymbolHover
                        type="outlined"
                        color="white"
                        text={mobileVideoPlayerShown ? 'hide' : 'watch!'}
                        onClick={() => handleMobileWatchVideoButtonClick()}
                    />
                </div>

                {mobileVideoPlayerShown && (
                    <VimeoPlayer
                        url={vimeoEmbedUrl}
                        play={mobileVideoPlayerShown}
                        onPlay={() => pushWVideoPlayEventToGTM()}
                        onStop={(e) => pushVideoStopEventToGTM(e)}
                        onPlaying={(d) => pushVideoPlayingEventToGTM(d)}
                    />
                )}
            </nav>

            <main id="webinar-content" style={{ marginTop: "-80px" }}>
                <section className="hero is-medium is-hidden-mobile" style={{ background: `url(${getSrc(data.bg)}) no-repeat center/cover` }}>
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-8">
                                    <h1 className="size-60 is-white">
                                        {title}
                                    </h1>
                                    <p className="size-38 is-purple-tertiary">
                                        {format(new Date(createdAt), "dd.MM.yyyy")}
                                    </p>
                                </div>
                                <div className="column is-4"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="hero is-large is-hidden-tablet" style={{ background: `url(${getSrc(data.bg)}) no-repeat center/cover` }}>
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-8">
                                <h1 className="size-50 is-white">
                                        {title}
                                    </h1>
                                    <p className="size-28 is-purple-tertiary">
                                        {format(new Date(createdAt), "dd.MM.yyyy")}
                                    </p>
                                </div>
                                <div className="column is-4"></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-grey">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <h2 className="font-variable size-56 is-transparent text-stroke-black">
                                    overview
                                </h2>
                                <ContentHTMLWithHoverPopUps
                                    contentHTML={overview.childMarkdownRemark.html}
                                    popUpItems={definedTermsPopUpItems}
                                    className="content"
                                />
                                <ShareButtons url={location.href} title={`Check out our video: ${title}`} />
                                <Button textClass="size-20" type="outlined" text="contact us" color="purple" to="/ask-us-anything" />
                            </div>

                            <div className="column is-hidden-mobile">
                                <div className="card is-white-bg sticky-card is-card-shadow" style={{ marginTop: "-140px" }}>
                                    <div className="card-content">
                                        <div className="has-text-centered">
                                            <h2 className="font-variable is-transparent margin-bottom-20 text-stroke-black size-50">
                                                watch the video
                                            </h2>
                                            <VimeoPlayer
                                                url={vimeoEmbedUrl}
                                                onPlay={(e) => pushWVideoPlayEventToGTM(e)}
                                                onStop={(e) => pushVideoStopEventToGTM(e)}
                                                onPlaying={(d) => pushVideoPlayingEventToGTM(d)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    );
}

export const query = graphql`
query ($contentful_id: String!, $contentful_content_vocabulary_item_slugs: [String]) {
    contentfulVideo (contentful_id: { eq: $contentful_id }) {
        title
        slug
        image {
            file {
                url
            }
        }
        createdAt
        overview {
            childMarkdownRemark {
                html
            }
        }
        vimeoEmbedUrl
        metaTags
    }

    bg: file(relativePath: { eq: "images/purple_blurry_bg.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(width: 1920, quality: 100)
        }
    }

    allContentfulDefinedTerm(
        filter: {slug: {in: $contentful_content_vocabulary_item_slugs}, node_locale: {eq: "en-US"}}
        ) {
        nodes {
            slug
            title
            
            description {
                childMarkdownRemark {
                    html
                    excerpt
                }
            }
        }
    }
}
`